<template>
  <div class="user-profile--meal-plan" v-if="user">
    <div v-if="user.profile">
      <v-row>
        <v-col cols="10">
          <div class="d-flex justify-space-between align-center mb-4">
            <h2>Assign New Meal Guide</h2>
            <v-btn class="primary--text ml-3" :to="{ name: 'user.meal-plan' }">
              Back
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <v-row v-if="form">
        <v-col cols="10">
          <v-row>
            <v-col cols="12" md="3">
              <v-menu
                v-model="showDatePicker"
                class="bbr-challenge--new-details-datepicker"
                transition="scale-transition"
                min-width="290px"
                :close-on-content-click="false"
                offset-y
                bottom
                left
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="selectedDate"
                    v-on="on"
                    label="Select Date"
                    readonly
                    outlined
                    hide-details
                  />
                </template>
                <v-date-picker
                  v-model="selectedDate"
                  @input="showDatePicker = false"
                  :allowed-dates="allowedStartDates"
                  scrollable
                  no-title
                />
              </v-menu>
            </v-col>
          </v-row>

          <template v-if="selectedDate">
            <form autocomplete="off">
              <search-meal-guide :form-data="form" />
            </form>

            <v-row no-gutters>
              <v-col cols="9">
                <v-switch
                  dense
                  class="mt-0 float-right"
                  v-model="form.status"
                  true-value="active"
                  false-value="deleted"
                  :label="`Search by: ${form.status}`"
                />
              </v-col>
              <v-col>
                <v-btn
                  class="bg-primary-gradient primary float-right"
                  @click="searchMealGuide"
                >
                  <span>Search for meal guides</span>
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </v-col>

        <v-col cols="10">
          <strong v-if="hasListData"
            >Results found: {{ listMeta.total }}</strong
          >

          <v-card outlined class="px-4 py-4 mt-2">
            <meals-list-table
              list-for="Active"
              :list="list"
              :meta="listMeta"
              :loading="loading"
              :assigning="assigning"
              @loadMore="loadMoreContent"
              @goTo="goTo"
            />
          </v-card>
        </v-col>
      </v-row>

      <v-overlay absolute :value="loading" opacity="0.1">
        <v-progress-circular
          indeterminate
          color="primary"
          size="64"
        ></v-progress-circular>
      </v-overlay>
    </div>

    <confirm-dialog ref="confirmAssign" />

    <v-toast ref="toast" />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import SearchMealGuide from '@/components/forms/foods/SearchMealGuide'
import MealsListTable from '@/views/Meals/components/ListTable'
import ConfirmDialog from '@/components/modals/ConfirmDialog'
import VToast from '@/components/elements/Toast'
import Event from '@/services/eventBus'
import Form from '@/utils/form'
import _, { pick } from 'lodash'
import dayjs from 'dayjs'

export default {
  name: 'AssignMealPlan',

  components: {
    VToast,
    SearchMealGuide,
    ConfirmDialog,
    MealsListTable,
  },

  data() {
    return {
      loading: false,
      assigning: true,
      showDatePicker: false,
      selectedDate: null,
      form: new Form({
        body_type: null,
        restriction_code: null,
        template_number: null,
        calories: null,
        status: 'active',
      }),
    }
  },

  computed: {
    ...mapState({
      user: (state) => state.users.selectedUser,
      list: (state) => state.meals.completed.list,
      listMeta: (state) => state.meals.completed.listMeta,
      selectedProgram: (state) => state.users.selectedUserProgram,
      currentMealGuide: (state) => state.users.selectedUser.current_meal_guide,
    }),

    hasListData() {
      return !!this.list.length
    },
  },

  methods: {
    ...mapActions({
      getGuideList: 'meals/getGuideList',
      getSelectedUserDetails: 'users/getSelectedUser',
      updateUserMealCalendar: 'users/updateUserMealCalendar',
    }),
    ...mapMutations({
      setFilter: 'meals/setFilter',
      clearList: 'meals/clearList',
    }),

    allowedStartDates(date) {
      let _day = Number(dayjs.utc(date).format('d'))

      return _day === 1 || false
    },

    async searchMealGuide() {
      this.setFilter({ completed: true })
      this.clearList()

      await this.fetchListData()
    },

    async fetchListData(page = 1) {
      if (this.loading) return

      this.loading = true

      let params = {
        restrictionCode: this.form.restriction_code,
        templateNumber: this.form.template_number,
        bodyType: this.form.body_type,
        calories: this.form.calories,
        status: this.form.status,
        page,
      }

      await this.getGuideList(params)

      this.loading = false
    },

    loadMoreContent() {
      if (this.listMeta.current_page < this.listMeta.last_page) {
        this.fetchListData(this.listMeta.current_page + 1)
      }
    },

    async goTo(id) {
      let guide = _.filter(this.list, function(guide) {
        return guide.id === id
      })

      const confirm = await this.$refs.confirmAssign.open(
        'Assign Meal Guide',
        'Are you sure you want to assign ' + guide[0].slug + ' ?'
      )

      if (!confirm) return

      this.loading = true

      await this.assignMealGuide(guide[0].id)

      this.loading = false
    },

    async assignMealGuide(id) {
      this.form.$busy = true

      await this.updateUserMealCalendar({
        mealGuideId: id,
        userId: this.user.id,
        startDate: this.selectedDate,
      })
        .then((data) => {
          Event.$emit('reload-user-profile')
          Event.$emit('reload-user-mealplan')
          this.$router.replace({ name: 'user.meal-plan' })
        })
        .catch((err) => {
          if (err.response && err.response.status === 422) {
            this.$refs.toast.open({
              color: 'warning',
              message: err.response.data.message,
            })
          }
        })

      this.form.$busy = false
    },
  },

  mounted() {
    let vm = this

    vm.clearList()

    setTimeout(function() {
      if (vm.currentMealGuide) {
        let data = pick(vm.currentMealGuide, [
          'body_type',
          'restriction_code',
          'template_number',
        ])

        vm.form = new Form(data)

        vm.searchMealGuide()
      }
    }, 2000)
  },

  created() {
    this.form.status = 'active'
  },
}
</script>

<style scoped></style>

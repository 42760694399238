<template>
  <div class="user-profile--meal-plan" v-if="user">
    <div>
      <v-row>
        <v-col md="10" cols="12">
          <h2>
            Meal Calendar
            <span v-if="currentMealGuide"> ({{ currentMealGuide.slug }})</span>
          </h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="10" cols="12">
          <div class="d-flex justify-space-between align-center mb-4 mt-2">
            <v-menu
              v-model="showMealDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-responsive max-width="344">
                  <v-text-field
                    v-on="on"
                    v-model="mealDate"
                    label="Select date"
                    clearable
                    readonly
                    outlined
                    flat
                    hide-details
                  />
                </v-responsive>
              </template>
              <v-date-picker
                ref="picker3"
                min="2020-01-01"
                v-model="mealDate"
                @input="showMealDate = false"
                @change="fetchData"
              />
            </v-menu>
            <v-btn
              class="bg-primary-gradient primary ml-3"
              :to="{ name: 'user.meal-plan-assign' }"
            >
              Assign New Meal Guide
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <v-row v-if="currentMealGuide && user.main_profile">
        <v-col md="10" cols="12">
          <v-card outlined class="mx-auto">
            <v-card-title class="grey lighten-4">
              <span class="primary--text">YOUR CALORIE + MACRO BREAKDOWN</span>
            </v-card-title>
            <v-divider />
            <v-card-text class="grey lighten-4">
              <div class="macros-items--container d-flex justify-space-between">
                <div class="macros-items--item">
                  <ul>
                    <li class="grey--text">Calories:</li>
                    <li>{{ totalCalories }}</li>
                  </ul>
                </div>
                <div class="macros-items--item">
                  <ul>
                    <li class="grey--text">Carbs:</li>
                    <li>
                      {{
                        user.main_profile.target_daily_macros
                          ? user.main_profile.target_daily_macros.carbs
                          : 0
                      }}
                    </li>
                  </ul>
                </div>
                <div class="macros-items--item">
                  <ul>
                    <li class="grey--text">Protein:</li>
                    <li>
                      {{
                        user.main_profile.target_daily_macros
                          ? user.main_profile.target_daily_macros.protein
                          : 0
                      }}
                    </li>
                  </ul>
                </div>
                <div class="macros-items--item">
                  <ul>
                    <li class="grey--text">Fat:</li>
                    <li>
                      {{
                        user.main_profile.target_daily_macros
                          ? user.main_profile.target_daily_macros.fats
                          : 0
                      }}
                    </li>
                  </ul>
                </div>
              </div>
            </v-card-text>
            <v-divider />
            <v-card-title class="grey lighten-4">
              <span class="primary--text">
                {{ currentMealGuide.calories }} kcal/day
              </span>
            </v-card-title>
            <v-divider />
            <v-card-text class="grey lighten-4">
              <div class="macros-items--container d-flex justify-space-between">
                <div class="macros-items--item">
                  <ul>
                    <li class="grey--text">Physical Activity:</li>
                    <li>
                      {{
                        user.main_profile.physical_activity
                          | readableEnums(physicalActivityTypes)
                      }}
                    </li>
                  </ul>
                </div>
                <div class="macros-items--item">
                  <ul>
                    <li class="grey--text">BMI:</li>
                    <li>{{ user.main_profile.bmi }}</li>
                  </ul>
                </div>
                <div class="macros-items--item">
                  <ul>
                    <li class="grey--text">Flagged:</li>
                    <li>{{ flagged }}</li>
                  </ul>
                </div>
              </div>

              <div class="macros-items--container d-flex justify-space-between">
                <div class="macros-items--item">
                  <ul>
                    <li class="grey--text">IBW:</li>
                    <li>{{ user.main_profile.ibw }}</li>
                  </ul>
                </div>
                <div class="macros-items--item">
                  <ul>
                    <li class="grey--text">EER:</li>
                    <li>{{ user.main_profile.eer }} kcal</li>
                  </ul>
                </div>
                <div class="macros-items--item">
                  <ul>
                    <li class="grey--text">Calories:</li>
                    <li>{{ user.main_profile.target_daily_calories }} kcal</li>
                  </ul>
                </div>
              </div>

              <div class="macros-items--container d-flex justify-space-between">
                <div class="macros-items--item">
                  <ul>
                    <li class="grey--text">Adjusted Goal:</li>
                    <li>
                      {{
                        user.main_profile.adjusted_target_goal
                          | readableEnums(targetGoals)
                      }}
                    </li>
                  </ul>
                </div>

                <div class="macros-items--item">
                  <ul>
                    <li class="grey--text">Adjusted Body Type:</li>
                    <li>
                      {{
                        user.main_profile.adjusted_body_type
                          | readableEnums(bodyTypes)
                      }}
                    </li>
                  </ul>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="programMeals">
        <v-col md="10" cols="12" v-if="programMeals">
          <profile-meal-guide :mealGuideData="programMeals" title="Meals" />
        </v-col>
      </v-row>

      <v-row v-else>
        <v-col>
          <h1 class="grey--text headline">NO MEALS UNDER MEAL GUIDE</h1>
        </v-col>
      </v-row>

      <v-overlay absolute :value="loading" opacity="0.1">
        <v-progress-circular
          indeterminate
          color="primary"
          size="64"
        ></v-progress-circular>
      </v-overlay>
    </div>

    <v-toast ref="toast" />
  </div>
</template>

<script>
import ProfileMealGuide from '@/components/ProfileMealGuide'
import VToast from '@/components/elements/Toast'
import { mapState, mapActions, mapGetters } from 'vuex'
import Event from '@/services/eventBus'
import dayjs from 'dayjs'

export default {
  name: 'MealPlanTab',

  components: { VToast, ProfileMealGuide },

  data() {
    return {
      meals: null,
      loading: false,

      totalCalories: 0,

      snackbar: {
        message: null,
        open: false,
        color: null,
      },

      mealDate: dayjs().format('YYYY-MM-DD'),
      showMealDate: false,
    }
  },

  computed: {
    ...mapState({
      user: (state) => state.users.selectedUser,
      programs: (state) => state.users.allUserPrograms,
      selectedProgram: (state) => state.users.selectedUserProgram,
      currentMealGuide: (state) =>
        state.users.selectedUserMealCalendar?.meal_guide,
      physicalActivityTypes: (state) =>
        state.users.extra.types.physicalActivities,
      targetGoals: (state) => state.users.extra.types.targetGoals,
      bodyTypes: (state) => state.meals.extra.bodyTypes,
    }),
    ...mapGetters({
      programMeals: 'users/selectedUserProgramMeals',
    }),

    flagged() {
      let flag = ''
      if (this.user) {
        if (this.user.main_profile.age < 18) {
          flag = 'Under Age'
        }
        if (this.user.main_profile.bmi > 24) {
          flag = (flag === '' ? '' : flag + ', ') + 'Overweight'
        }
        if (this.user.main_profile.bmi < 19) {
          flag = (flag === '' ? '' : flag + ', ') + 'Underweight'
        }
      }

      return flag
    },
  },

  async created() {
    Event.$on('reload-user-mealplan', () => {
      this.fetchData()
    })
  },

  mounted() {
    if (this.$attrs.id) {
      const timer = setInterval(() => {
        this.fetchData()
        clearInterval(timer)
      }, 1000)
    }
  },

  methods: {
    ...mapActions({
      getSelectedUserMealCalendar: 'users/getSelectedUserMealCalendar',
    }),

    async fetchData() {
      const userId = this.$attrs.id

      try {
        this.loading = true
        await this.getSelectedUserMealCalendar({ userId, date: this.mealDate })
        this.loading = false
      } catch (error) {
        this.loading = false
        this.$refs.toast.open({
          color: 'red',
          message: `No meal calendar set for ${this.mealDate}`,
        })
      }

      if (this.programMeals) {
        this.totalCalories = 0
        this.programMeals.forEach((i) => {
          if (i.food) {
            this.totalCalories += i.food.calories
          }
        })
      }
    },
  },
}
</script>

<style lang="scss">
.user-profile {
  &--basic {
    width: 50%;
    padding: 0 10px 0 10px;

    &:last-child {
      width: auto;
    }
  }

  ul {
    padding-left: 0;
    list-style: none;

    li {
      color: var(--v-grey-base) !important;

      &:not(:first-child) {
        color: var(--v-primary-base) !important;
        font-weight: 600 !important;
      }
    }
  }

  .macros-items {
    &--data {
      margin-top: 50px;
    }

    &--selection {
      width: auto;

      .v-input__append-inner {
        margin-top: 5px !important;
      }
    }

    &--item {
      width: 25%;
    }

    &--container {
      margin-bottom: 5px;
    }

    &--nodata {
      height: 200px;
    }
  }
}
</style>

<template>
  <v-simple-table>
    <thead>
      <tr>
        <th>Body Type</th>
        <th>Template Name</th>
        <th>Template Number</th>
        <th>Calories</th>
        <th v-if="assigning"></th>
      </tr>
    </thead>
    <tbody
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="disableListScroll"
    >
      <tr class="no-data" v-if="list.length === 0 && !loading">
        <td colspan="7" class="text-center headline grey--text py-12">
          NO DATA
        </td>
      </tr>

      <tr
        v-for="row in list"
        :key="row.id"
        class="clickable"
        @click="showDetails(row)"
      >
        <td>{{ row.body_type | readableEnums(bodyTypes) }}</td>
        <td>{{ row.restriction_code }}</td>
        <td>{{ row.template_number }}</td>
        <td>{{ row.calories }}</td>
        <td v-if="assigning" class="text-right"><v-btn> Assign </v-btn></td>
      </tr>

      <tr class="table-skeleton-loader" v-if="loading">
        <td class="pl-0">
          <v-skeleton-loader type="list-item" />
        </td>
        <td class="pr-0">
          <v-skeleton-loader type="list-item" />
        </td>
        <td class="pr-0">
          <v-skeleton-loader type="list-item" />
        </td>
        <td class="pr-0">
          <v-skeleton-loader type="list-item" />
        </td>
        <td class="pr-0" v-if="assigning">
          <v-skeleton-loader type="list-item" />
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
import infiniteScroll from 'vue-infinite-scroll'
import { mdiDotsVertical } from '@mdi/js'
import { mapState } from 'vuex'

export default {
  name: 'RecipeListTable',

  components: {},

  directives: {
    infiniteScroll,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    assigning: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: () => [],
      required: true,
    },
    meta: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      icons: {
        action: mdiDotsVertical,
      },
    }
  },

  computed: {
    ...mapState({
      bodyTypes: (state) => state.meals.extra.bodyTypes,
    }),

    disableListScroll() {
      return this.loading || this.meta.current_page >= this.meta.last_page
    },
  },

  methods: {
    loadMore() {
      this.$emit('loadMore', this.meta.current_page + 1)
    },

    showDetails(row) {
      this.$emit('goTo', row.id)
    },
  },
}
</script>

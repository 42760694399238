<template>
  <v-card outlined class="mx-auto">
    <v-card-title class="primary--text" color="primary lighten-4">
      {{ this.title }}
    </v-card-title>
    <v-card-text>
      <v-row no-gutter v-for="(row, index) in data" :key="index">
        <v-col cols="3">
          <h3>{{ row.meal_type | readableEnums(mealTypes) }}</h3>
        </v-col>
        <v-col cols="9">
          <div v-if="row.food_name">
            <v-card class="mx-auto" outlined>
              <v-card-title class="subtitle-1 font-weight-bold">
                {{ row.food_name }}
              </v-card-title>
            </v-card>
          </div>
          <div v-else>
            <div v-if="row.food && row.food.ingredients.length === 0">
              <v-card class="mx-auto" outlined>
                <v-card-title class="subtitle-1 font-weight-bold">
                  {{ row.food.title }}
                </v-card-title>
              </v-card>
            </div>
            <v-card outlined class="mx-auto" v-else>
              <v-img
                v-if="row.food && row.food.cloudinary_image"
                class="white--text align-end"
                :src="row.food.cloudinary_image.medium"
                max-height="300px"
              >
              </v-img>
              <v-card-title class="subtitle-1 font-weight-bold" v-if="row.food">
                {{ row.food.title }}
              </v-card-title>

              <v-divider></v-divider>

              <v-card-title class="subtitle-2 font-weight-bold">
                Ingredients
              </v-card-title>

              <v-list dense tile>
                <v-list-item v-for="(item, k) in row.food.ingredients" :key="k">
                  <v-list-item-content>
                    {{ item.title }}
                  </v-list-item-content>
                  <v-list-item-content class="align-end">
                    <span class="text-right">
                      {{ item.food_ingredient.serving_quantity }}
                      {{ item.food_ingredient.serving_unit }}
                    </span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-divider></v-divider>

              <v-card-title class="subtitle-2 font-weight-bold">
                Flavour Boosters
              </v-card-title>

              <v-list dense tile>
                <v-list-item
                  v-for="(item, k) in row.food.flavour_boosters"
                  :key="k"
                >
                  <v-list-item-content>
                    {{ item }}
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-divider></v-divider>

              <v-card-title class="subtitle-2 font-weight-bold">
                Directions
              </v-card-title>

              <v-list dense tile>
                <v-list-item v-for="(method, j) in row.food.methods" :key="j">
                  <v-list-item-content>
                    <v-row no-gutters>
                      <v-col cols="1">
                        <span class="font-weight-medium">
                          {{ j + 1 }}
                        </span>
                      </v-col>
                      <v-col>{{ method }}</v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ProfileMealGuide',

  props: {
    mealGuideData: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
    },
  },

  data() {
    return {
      feature: null,
      data: this.mealGuideData,
    }
  },

  computed: {
    ...mapState({
      mealTypes: (state) => state.meals.extra.mealTypes,
    }),
  },

  watch: {
    mealGuideData() {
      this.data = this.mealGuideData
    },
  },
}
</script>
